.altai-panel-building-block-creator {
  color: #1b1b1b;
  display: none;
  display: inline-block;
  background-color: #007aff;
  border: 5px solid #212121;
  font-size: 16px;
  padding: 10px;
  margin: 5px;
  user-select: none;
  -webkit-user-select: none;
  z-index: 9999;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.8em;
  opacity: 0;
}
.altai-panel-building-block-creator .footer-inner {
  padding: 5px;
}
.altai-panel.edit-mode:hover .altai-panel-building-block-creator,
.altai-panel.local-edit .altai-panel-building-block-creator {
  opacity: 1;
}
